import { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { thumbLoader } from '../utils/custom-image-loader'

const CardSubCategories = ({ link = '', slug = '', image, dynamicMediaUrl = null, title, campaignName = null, dateReference = null }) => {
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const dynamicMediaSrc = dynamicMediaUrl || image
  const [imageSrc, setImageSrc] = useState(dynamicMediaSrc || brokenImage)

  const isNotAuthored = (url) => {
    const regex = /^https?:\/\/cdn\.popshelf\.com\.thumb\.\d+\.\d+\.com\/\?(\d+)$/
    return regex.test(url)
  }

  useEffect(() => {
    if (isNotAuthored(imageSrc)) {
      setImageSrc(brokenImage)
    }
  }, [])

  if (link || slug) {
    return (
      <Link href={link || slug}>
        <a s_objectid={`${title} | N/A | ${campaignName} | ${dateReference}`} className="pop-sub-categories-focus" aria-label={title}>
          <div className="pop-sub-categories">
            <div className="category-image-wrap">
              <div className="category-image">
                {imageSrc && (
                  <Image
                    src={imageSrc}
                    alt={title}
                    loader={thumbLoader}
                    layout="fill"
                    aria-label={title}
                    onError={() => setImageSrc(brokenImage)}
                  />
                )}
              </div>
            </div>
            <div>
              <p>{title}</p>
            </div>
          </div>
        </a>
      </Link>
    )
  } else {
    return (
      <div className="pop-sub-categories pop-sub-categories-focus">
        <div className="category-image-wrap">
          <div className="category-image">
            {imageSrc && (
              <Image
                src={imageSrc}
                alt={title}
                loader={thumbLoader}
                layout="fill"
                aria-label={title}
                onError={() => setImageSrc(brokenImage)}
              />
            )}
          </div>
        </div>
        <div>
          <p>{title}</p>
        </div>
      </div>
    )
  }
}

export default CardSubCategories
