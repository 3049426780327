import { useState } from 'react'
import Image from 'next/image'
import { mediumLoader } from '../utils/custom-image-loader'
import SVG from './SVG'

const FeaturedTile = ({ data }) => {
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const dynamicImage = data.dynamicMediaUrl || data.image_url
  const [imageSrc, setImageSrc] = useState(dynamicImage || brokenImage)
  const dynamicMobileImage = data.dynamicMediaMobileUrl || data.image_url
  const [mobileImageSrc, setMobileSrc] = useState(dynamicMobileImage || brokenImage)

  return (
    <div data-testid="featured-tile-container" className="featured-tile container">
      <div className="featured-tile__container">
      {(imageSrc || mobileImageSrc) && (
        <a data-testid="featured-tile__image-anchor" s_objectid={`${data.title} | N/A | ${data.campaign_name} | ${data.date_reference}`} className="featured-tile__image" href={data.link}>
          {mobileImageSrc &&
            <div className="image-next featured-tile__image--mobile">
              <Image
                src={mobileImageSrc}
                alt={data.alt}
                layout="fill"
                loader={mediumLoader}
                onError={() => setMobileSrc(brokenImage)}
              />
            </div>
          }
          {imageSrc &&
            <div className="image-next featured-tile__image--desktop">
              <Image
                src={imageSrc}
                alt={data.alt}
                layout="fill"
                loader={mediumLoader}
                onError={() => setImageSrc(brokenImage)}
              />
            </div>
          }
        </a>
      )}
      <div className="featured-tile__content-panel">
        <div className="bubbles">
          <SVG name="rings" label="rings"></SVG>
        </div>
        <a aria-label={data.title} s_objectid={`${data.title} | N/A | ${data.campaign_name} | ${data.date_reference}`} className="content" href={data.link} tabIndex={-1}>
          {data.title && <h2 className="headline">{data.title}</h2>}
          {data.bodyText && <p className="body">{data.bodyText}</p>}
          <div className="link">
            <SVG name="arrow-right" label="Arrow-right" size="md" />
          </div>
        </a>
      </div>
      </div>
    </div>
  )
}

/**
 * Export interface
 */
export default FeaturedTile
