import { useEffect, useRef } from 'react'

const UGCContentBlock = ({ data }) => {
  const { title, subtitle, html, scriptId, scriptSrc } = data
  const ugcBlock = useRef(null)
  const sprinklrScript = useRef(null)
  const resizeObserver = useRef(null)

  const decodeHtml = () => {
    const txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value
  }

  const outputsize = (e) => {
    if (ugcBlock.current?.offsetWidth && !sprinklrScript.current) {
      sprinklrScript.current = document.createElement('script')
      sprinklrScript.current.type = 'text/javascript'
      sprinklrScript.current.async = true
      sprinklrScript.current.id = scriptId
      sprinklrScript.current.src = scriptSrc
      document.head.appendChild(sprinklrScript.current)
    }
  }

  const cleanupAssets = () => {
    if (resizeObserver.current && ugcBlock.current) {
      resizeObserver.current.unobserve(ugcBlock.current)
    }

    if (resizeObserver.current && !ugcBlock.current) {
      resizeObserver.current.disconnect()
    }

    if (sprinklrScript.current && sprinklrScript.current.parentNode === document.head) {
      document.head.removeChild(sprinklrScript.current)
    }

    if (window.SprinklrGlobal && typeof window.SprinklrGlobal.destroy === 'function') {
      window.SprinklrGlobal.destroy()
    }
  }

  useEffect(() => {
    resizeObserver.current = new ResizeObserver(outputsize)

    if (ugcBlock.current) {
      resizeObserver.current.observe(ugcBlock.current)
    }

    return () => cleanupAssets()
  }, [])

  return (
    <div className="ucg__content-block">
      <div className="ucg__content-block__info">
        <p className="ucg__content-block__info-title">{title}</p>
        <p className="ucg__content-block__info-sub">{subtitle}</p>
      </div>
      <div
        ref={ugcBlock}
        className="ucg__content-block__sprinklr"
        dangerouslySetInnerHTML={{
          __html: decodeHtml()
        }}
      />
    </div>
  )
}

export default UGCContentBlock
